/* .rdt_Table,
.hFVRPL,
.ecDzaP,
.fhaVWy,
.sc-hHEiqL,
.bIRQQN,
.kofSid,
.rdt_TableCell,
.rdt_TableCol,
.iCxiXr,
.rdt_Pagination {
  background-color: #f7fafc !important;
} */

.rdt_TableBody {
  overflow-y: visible !important;
  overflow: visible !important;
}

.react-datepicker-wrapper {
  max-width: 100% !important;
  width: 100% !important;
}

.rbc-calendar {
  z-index: 0;
  position: relative;
}

.notification-detail h4, .notification-detail h3 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 2px;
}

.notification-detail li:nth-child(3n) {
  margin-bottom: 20px;
}